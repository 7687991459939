import { useUser } from "../api/user";

type navItem = {
  label: string;
  path: string;
  claimByPermission?: number[];
  claimByDepartment?: string;
  claimByRole?: "admin" | "Member";
};

const registeringStdNav: Record<string, navItem[]> = {
  Registration: [
    // { label: "Hotel Accommodation", path: "/hotel" },
    { label: "Registration", path: "/orientation" },
    { label: "Withdrawal", path: "/withdrawal" },
  ],
};

const studentNavigation: Record<string, navItem[]> = {
  Services: [
    // { label: "Hotel Accommodation", path: "/hotel" },
    {
      label: "Food Basket",
      path: "/food-basket",
    },
    {
      label: "Events",
      path: "/events",
    },
  ],
  Documents: [
    {
      label: "Documents",
      path: "/documents",
    },
  ],
  "Co-op": [{ label: "Co-op", path: "/coop" }],
  Registration: [
    { label: "Withdrawal", path: "/withdrawal" },
    { label: "Completion Letter", path: "/completion" },
    { label: "Retake", path: "/retake" },
    { label: "Convocation", path: "/convocation" },
  ],
  Schedules: [{ label: "Schedules", path: "/schedules" }],
  AIP: [{ label: "AIP", path: "/aip" }],
};

const staffNavigation: Record<string, navItem[]> = {
  Services: [
    {
      label: "Events",
      path: "/events",
      claimByPermission: [0, 2],
      claimByDepartment: "Events",
    },
    {
      label: "Food Basket",
      path: "/food-basket",
      claimByPermission: [0],
    },
    // { label: "Hotel Accommodation", path: "/hotel", claimByPermission: [0, 2] },
  ],
  idCards: [
    {
      label: "ID Cards",
      path: "/idcard",
      claimByPermission: [0, 2],
      claimByDepartment: "Orientation",
    },
  ],
  "Student Profile": [
    {
      label: "Student Profile",
      path: "/profile/",
      claimByPermission: [0, 2],
      claimByDepartment: "Profile",
    },
    {
      label: "Withdrawal",
      path: "/withdrawal/",
      claimByPermission: [0, 2],
      claimByDepartment: "Orientation",
      claimByRole: "admin",
    },
    {
      label: "Completion Letters",
      path: "/completion/",
      claimByPermission: [0, 2],
      claimByDepartment: "Orientation",
      claimByRole: "admin",
    },
    {
      label: "Retake",
      path: "/retake",
      claimByPermission: [0, 2],
      claimByDepartment: "Orientation",
      claimByRole: "admin",
    },
    {
      label: "Convocation",
      path: "/convocation",
      claimByPermission: [0, 2],
      claimByDepartment: "Orientation",
    },
  ],
  Registration: [
    {
      label: "Registration",
      path: "/orientation",
      claimByPermission: [0, 2],
      claimByDepartment: "Orientation",
    },
    {
      label: "Add Students",
      path: "/createStudents",
      claimByPermission: [0, 2],
      claimByDepartment: "Orientation",
      claimByRole: "admin",
    },
    {
      label: "All Students",
      path: "/students/list",
      claimByPermission: [0, 2],
      claimByDepartment: "Orientation",
    },
  ],
  Schedules: [
    {
      label: "View Groups",
      path: "/groups/",
      claimByPermission: [0, 2],
      claimByDepartment: "Schedules",
    },
    {
      label: "Student List",
      path: "/selected/groups/",
      claimByPermission: [0, 2],
      claimByDepartment: "Schedules",
    },
    {
      label: "Group Eligibility",
      path: "/students/groupEligibilityList/",
      claimByPermission: [0, 2],
      claimByDepartment: "Schedules",
    },
  ],
  Coop: [
    {
      label: "Co-op",
      path: "/coop",
      claimByPermission: [0, 2],
      claimByDepartment: "Career",
    },
    {
      label: "Employers",
      path: "/employers",
      claimByPermission: [0, 2],
      claimByDepartment: "Career",
    },
  ],
  Aip: [
    {
      label: "AIP",
      path: "/aip",
      claimByPermission: [0, 2],
      claimByDepartment: "Aip",
    },
  ],
  Documents: [
    {
      label: "Documents",
      path: "/documents",
      claimByPermission: [0, 2],
    },
  ],
  Reports: [
    {
      label: "Reports",
      path: "/reports",
      claimByPermission: [0, 2],
    },
  ],
  Settings: [
    {
      label: "Staff Settings",
      path: "/settings/users",
      claimByPermission: [0],
    },
    {
      label: "Intake Settings",
      path: "/settings/intakes",
      claimByPermission: [0],
    },
    {
      label: "Pre Schedules",
      path: "/settings/preSchedules",
      claimByPermission: [0],
    },
    {
      label: "Post Schedules",
      path: "/settings/postSchedules",
      claimByPermission: [0],
    },
    {
      label: "Departments",
      path: "/settings/departments",
      claimByPermission: [0],
    },
  ],
};

/**
 * Get the user's navigation data based on the permission
 */
export const useNavigationData = (): {
  navData: Record<string, navItem[]>;
  isNavLoading: boolean;
} => {
  const { data: currentUser, isLoading: isUserLoading } = useUser();
  let navData: Record<string, navItem[]> = {};
  let isNavLoading = isUserLoading;

  if (!isUserLoading) {
    const userPermission: number = currentUser?.permission as number;
    let userDepartments: string[] = [];
    let userRoles: Record<string, "admin" | "Member"> = {};

    // student just return the student version
    if (userPermission === 1) {
      return { navData: studentNavigation, isNavLoading: false };
    }

    if (userPermission === 3) {
      return { navData: registeringStdNav, isNavLoading: false };
    }

    // if super just return all
    if (userPermission === 0) {
      return { navData: staffNavigation, isNavLoading: false };
    }

    // transform user's department into just array
    currentUser?.departments?.map((dept) => {
      userDepartments.push(dept.name);
      userRoles[dept.name] = dept.role;
    });

    // if staff then check based on department
    navData = { ...staffNavigation }; // make a shallow copy of the staff navigation in nav

    // the keys will stay the same, will not be removed

    // loop list of group
    Object.keys(navData).map((navGroup) => {
      const navGroupLength = navData[navGroup].length;
      // prevent reindexing when item is remove - looping in reverse
      for (let i = navGroupLength - 1; i >= 0; --i) {
        // permission check - remove data that is not belongs to current user
        if (!navData[navGroup][i].claimByPermission?.includes(userPermission)) {
          navData[navGroup].splice(i, 1);
        }

        // department check
        if (navData[navGroup][i]?.claimByDepartment !== undefined) {
          let dept = navData[navGroup][i]?.claimByDepartment as string;
          if (!userDepartments.includes(dept)) {
            navData[navGroup].splice(i, 1);
          }
        }

        // role check
        if (
          navData[navGroup][i]?.claimByDepartment !== undefined &&
          navData[navGroup][i]?.claimByRole !== undefined
        ) {
          let dept = navData[navGroup][i]?.claimByDepartment as string;
          let role = navData[navGroup][i]?.claimByRole as string;
          if (userRoles[dept] !== role) {
            navData[navGroup].splice(i, 1);
          }
        }
      }
    });

    return { navData, isNavLoading: false };
  }

  // navigation hook consumer need the loading to know the promise is done
  return { navData, isNavLoading };
};
